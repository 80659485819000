import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { LeadService } from '../../../services/lead/lead.service';
import { ErrorService } from '../../../services/error/error.service';
import { Message } from '../../../utils/message';
import { FidelityService } from '../../../services/fidelity/fidelity.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'gen-monorepo-drawer-filters-coupon',
  templateUrl: './drawer-filters-coupon.component.html',
  styleUrl: './drawer-filters-coupon.component.scss',
})
export class DrawerFiltersCouponComponent {
  public filterFormSide: FormGroup;
  public filterForm: FormGroup;
  public mallForm: FormGroup;

  @Input() public filterHome: FormGroup;

  public categories: any[] = [];
  public malls: any[] = [];
  public typeCupons: any[] = [
    { label: 'Porcentagem', value: 'PERCENT' },
    { label: 'Valor fixo', value: 'REAL_VALUE' },
  ];

  constructor(
    private $lead: LeadService,
    private $error: ErrorService,
    public $drawerRef: NzDrawerRef,
    private fb: FormBuilder,
    private $fidelity: FidelityService,
    private $notification: StateManagementService
  ) { }

  public ngOnInit(): void {
    this.getCategory();
    this.getMallList();
    this.createFilterForm();
  }

  private getMallList(): void {
    this.$fidelity.getMallList().subscribe({
      next: (res) => {
        this.malls = res?.data?.malls.results.map((mall) => {
          return {
            label: mall.id,
            value: mall.id,
            checked: false,
          };
        });
        this.createMallForm();
      },
      error: (error) => {
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }

  private createMallForm(clear?: boolean): void {
    this.mallForm = this.fb.group({
      mallListOptions: this.fb.array([]),
    });

    this.initMalls();
  }

  private initMalls(): void {
    const mallForm = this.mallForm.get('mallListOptions') as FormArray;

    const checkedMalls = this.filterHome?.value?.mall;

    this.malls.forEach((mall) => {
      const checkedMall = checkedMalls.find(checked => checked.value === mall.value);
      
      mallForm.push(
        this.fb.group({
          label: [mall.label],
          value: [mall.value],
          checked: [ checkedMall || mall.checked],
        })
      );
    });

    this.mallFormValueChanges();
  }

  public mallFormValueChanges() {
    this.mallForm.valueChanges.subscribe((changes) => {
      this.changeMallFilter(changes);
    });
  }

  private createFilterForm(clear?: boolean): void {
    this.filterForm = this.fb.group({
      mall: this.fb.array([]),
      category: this.fb.array([]),
      typeDiscount: this.fb.array([]),
      orderBy: this.fb.control('RELEVANCE'),
    });

    if (!clear && (this.filterHome?.value?.category?.length > 0 || this.filterHome?.value?.typeDiscount?.length > 0 || this.filterHome?.value?.mall?.length > 0)) {
      this.filterForm = this.filterHome;
    }

    this.filterFormValueChanges();
  }

  public filterFormValueChanges() {
    this.filterForm.valueChanges.subscribe((changes) => {
      if (changes) {
        this.$notification.setFormFilter(this.filterForm);
      }
    });
  }

  public changeCategoryFilter(value: string): void {
    const categoryFilter = this.filterForm.get('category') as FormArray;
    categoryFilter.push(this.fb.control(value));
  }

  public changeTypeCouponFilter(value: string): void {
    const typeCouponFilter = this.filterForm.get('typeDiscount') as FormArray;
    typeCouponFilter.push(this.fb.control(value));
  }

  public changeMallFilter(value: any): void {
    const mallFilter = this.filterForm.get('mall') as FormArray;

    const mallList = value;

    const checkedFields = mallList.mallListOptions.filter((mall) => {
      if (mall.checked) return mall;
    });

    checkedFields.forEach((mall) => {
      const alreadyExist = mallFilter.controls.some((control) => {
        return control.get('value')?.value == mall.value ? true : false;
      });
      if (alreadyExist) return;
      else {
        mallFilter.push(
          this.fb.group({
            label: mall.label,
            value: mall.value,
            checked: mall.checked,
          })
        );
      }
    });
  }

  private getCategory(): void {
    this.$lead.getCategories().subscribe({
      next: (res) => {
        this.categories = res?.data?.allCategoriesCouponLead;
      },
      error: (error) => {
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }

  public getMallListFormArray(): FormArray {
    return this.mallForm.get('mallListOptions') as FormArray;
  }

  public getMallFormArray(): FormArray {
    return this.filterForm.get('mall') as FormArray;
  }

  public getFilterFormValue(): any {
    let allFilterValue = this.filterForm?.getRawValue();

    if (allFilterValue?.orderByPriceDesc) {
      return allFilterValue;
    }

    if (allFilterValue?.mall) {
      const mallFilter = allFilterValue?.mall?.filter((mall) => {
        if (mall?.checked) return mall;
      });
      allFilterValue.mall = mallFilter.map((mall) => {
        return mall?.value;
      });
    }

    if (allFilterValue?.category) {
      allFilterValue.category = allFilterValue.category.map((category) => {
        return category?.id;
      });
    }

    if (allFilterValue?.typeDiscount) {
      allFilterValue.typeDiscount = allFilterValue?.typeDiscount.map((typeDiscount) => {
        return typeDiscount?.value;
      });
    }

    if (allFilterValue?.orderBy) {
      allFilterValue.orderBy = allFilterValue?.orderBy;
    }

    return allFilterValue;
  }

  public getFilterFormLabel(): any {
    let allFilterValue = this.filterForm?.getRawValue();

    if (allFilterValue?.mall) {
      const mallFilter = allFilterValue?.mall.filter((mall) => {
        if (mall?.checked) return mall;
      });

      allFilterValue.mall = mallFilter.map((mall) => {
        return mall?.label;
      });
    }

    if (allFilterValue?.category) {
      allFilterValue.category = allFilterValue?.category.map((category) => {
        return category?.name;
      });
    }

    if (allFilterValue?.typeDiscount) {
      allFilterValue.typeDiscount = allFilterValue?.typeDiscount.map((typeDiscount) => {
        return typeDiscount?.label;
      });
    }

    if (allFilterValue?.orderBy) {
      allFilterValue.orderBy = allFilterValue?.orderBy;
    }

    return allFilterValue;
  }

  public removeFilter(type, index, mall?): void {
    switch (type) {
      case 'mall': {
        const mallForm = this.getMallFormArray();
        mallForm.removeAt(index);
        const mallListForm = this.getMallListFormArray();
        mallListForm.controls.forEach((mallControl) => {
          if (mallControl.get('value')?.value === mall) mallControl.get('checked').setValue(false);
        });
        break;
      }
      default: {
        const array = this.filterForm.get(type) as FormArray;
        array.removeAt(index);
        break;
      }
    }
  }

  public clearAllFilters(): void {
    this.createFilterForm(true);
    this.createMallForm(true);
    this.$notification.setFormFilter('clear');
  }

}
